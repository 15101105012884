<template>
  <div
    class="digital-tights"
    :style="$route.name === 'mall' ? 'height:774px' : ''"
  >
    <div class="title">
      <div class="title-main">
        <h2>
          Digital equity<span>数字权益</span>
        </h2>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "digital-tights",

  data() {
    return {};
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.digital-tights {
  border-top: 1px solid var(--subjectColor);
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .title {
    width: 100%;
    height: 80px;
    background: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    z-index: 1;
    .title-main {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      line-height: 80px;
      h2 {
        font-size: 28px;
        font-family: SF UI Display;
        font-weight: 600;
        color: #333333;
        span {
          font-family: FZZhengHeiS-DB-GB;
          font-weight: 500;
          margin-left: 12px;
        }
      }
    }
  }
}
</style>